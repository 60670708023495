










import { Component, Prop, Vue } from 'vue-property-decorator';
// import api from '@/sdk/api/api';
@Component({
  components: {
    'navLayout1': () => import('@/components/business/pc/header/InsNavLayout1.vue'),
    'navLayout2': () => import('@/components/cmxBusiness/pc/header/InsNavLayout2.vue')
  }
})
export default class InsMenu extends Vue {
  @Prop({ default: 'header' }) private type!: string;
    CurrentNav: string = 'navLayout2';

    get NavData () {
      if (this.type === 'header') {
        return this.$store.state.headerMenus;
      } else {
        return this.$store.state.footerMenus;
      }
    }

    mounted () {
      var _this = this;
    }
}
